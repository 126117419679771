
/**
 * @name: 商品管理-商品价格属性管理
 * @author:
 * @date: 2023-08-04 17:53
 * @description： 商品管理-商品价格属性管理
 * @update: 2023-08-04 17:53
 */
import {Vue, Component} from "vue-property-decorator"
import type {ICrudOption} from "@/types/m-ui-crud"
import {IPage} from "@/apis/page";
import {productPriceAttrCreateApi, productPriceAttrQueryApi, productPriceAttrRemoveApi} from "@/apis/product/priceAttr";
import {IProductPriceAttr} from "@/apis/product/priceAttr/types";

@Component({})
export default class productPriceAttr extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: [] = []
  // 表格总数
  tableTotal: number = 0
  // 查询参数
  queryParam: IPage = {
    page: 1,
    limit: 10,
  }
  // 表单参数
  modelForm: Partial<IProductPriceAttr> = {}
  // crud配置信息
  crudOption: ICrudOption = {
    searchBox: false,
    addTitle: '新增单位',
    menu: true,
    dialogWidth: '400px',
    column: [
      {
        label: "价格属性",
        prop: "attrName",
        align: "center",
        span: 24,
        maxlength: 4,
        rules: [
          {required: true, message: "请输入名称", trigger: "blur"},
          {
            trigger: 'blur', validator: (rule: any, value: any, callback: any) => {
              if (!/^[\da-z\u2E80-\u9FFF]{1,4}$/i.test(value)) {
                callback(new Error('数据类型数字、字母、汉字，最大长度4'));
              } else {
                callback();
              }
            }
          }
        ]
      },
    ]
  }

  /**
   * 新增提交
   */
  submitSave(form: any, done: any, loading: any) {
    productPriceAttrCreateApi(form).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.getList();
        done();
      }
    }).finally(() => {
      loading();
    })
  }

  /**
   * 删除提交
   * @param form
   */
  submitRemove(form: IProductPriceAttr, done: any, loading: any) {
    productPriceAttrRemoveApi (form.id).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.getList();
        loading();
        done();
      }
    })
  }

  /**
   * 获取列表数据
   */
  getList() {
    productPriceAttrQueryApi().then(e => {
      //@ts-ignore
      this.tableData = e;
    })
  }

  created() {
    this.getList()
  }
}
