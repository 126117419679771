
/**
 * @name: 档口管理-收银秤管理
 * @author: itmobai
 * @date: 2023-08-04 17:53
 * @description： 档口管理-收银秤管理
 * @update: 2023-08-04 17:53
 */
import { Vue, Component } from "vue-property-decorator"
import { gateGetGateListApi } from "@/apis/gate/info"
import { productPriceAttrQueryApi } from "@/apis/product/priceAttr"
import { cashierScaleQueryApi, cashierScaleModifyApi, cashierScaleCreateApi, cashierScaleDetailApi, cashierScaleUpdateScaleAttrApi } from "@/apis/gate/scale"
import type { IProductPriceAttr } from "@/apis/product/priceAttr/types"
import { IGate } from "@/apis/gate/info/types";
import { IScale, IScaleParam } from "@/apis/gate/scale/types"
import { deepCopy } from "@/utils/common";

const checkAlphaNumeric = (rule: any, value: any, callback: any) => {
  if (!/^[a-zA-Z0-9]+$/.test(value)) {
    return callback(new Error('请输入字母或数字'));
  }
  callback()
}

@Component({})
export default class gateScale extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: IScale[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IScaleParam = {
    page: 1,
    limit: 10,
    productPriceAttr: [],
    cashierScaleSn: '',
    gateIdList: [],
    status: '',
    isWipeZero: '',
    isHangAccount: '',
    isPendingOrder: ''
  }
  // 表单参数
  modelForm: Partial<IScale> = {}
  // 弹窗开关
  dialogVisible = false
  // 弹窗表单加载状态
  formLoading = false
  // 档口列表
  gateList: IGate[] = []
  // 商品价格属性列表
  productPriceAttrList: IProductPriceAttr[] = []
  // 状态下拉
  statusList: any = [
    {
      label: "全部",
      value: ''
    },
    {
      label: "正常",
      value: 1
    },
    {
      label: "废弃",
      value: 2
    },
  ]
  // 布尔下拉
  boolList: any = [
    {
      label: "全部",
      value: ''
    },
    {
      label: "启用",
      value: true
    },
    {
      label: "禁用",
      value: false
    },
  ]
  // 通用下拉
  normalList: any = [
    {
      label: "全部",
      value: ''
    },
    {
      label: "启用",
      value: 1
    },
    {
      label: "禁用",
      value: 2
    },
  ]
  // 表单校验规则
  rules: any = {
    deviceSn: [
      {required: true, message: '请输入设备编号', trigger: 'blur'},
      {validator: checkAlphaNumeric, trigger: 'blur'}
    ],
    cashierScaleSn: [
      {required: true, message: '请输入收银秤编号', trigger: 'blur'},
      {validator: checkAlphaNumeric, trigger: 'blur'}
    ],
    terminalSn: [
      {required: true, message: '请输入terminalSn', trigger: 'blur'},
    ],
    terminalKey: [
      {required: true, message: '请输入terminalKey', trigger: 'blur'},
    ]
  }

  search () {
    this.queryParam.page = 1
    this.getList()
  }

  reset () {
    // @ts-ignore
    this.$refs.CusQueryRef.resetFields()
    this.queryParam.productPriceAttr = this.productPriceAttrList.map(item => {
      return {
        productPriceAttrId: item.id,
        status: ''
      }
    })
    // 预操作(帮助清理分页参数)
    this.queryParam.page = 1
    this.queryParam.limit = 10
    this.getList()
  }

  /**
   * 获取档口下拉列表
   */
  getGateList () {
    gateGetGateListApi().then(e => {
      this.gateList = e;
    })
  }

  /**
   * 获取价格属性
   */
  getAttrList () {
    return new Promise(resolve => {
      productPriceAttrQueryApi().then(e => {
        this.productPriceAttrList = e
        this.queryParam.productPriceAttr = (e || []).map(item => {
          return {
            productPriceAttrId: item.id,
            status: ''
          }
        })
        resolve(null)
      })
    })
  }

  handleCurrentChange (page: number) {
    this.queryParam.page = page;
    this.getList()
  }

  handleSizeChange (size: number) {
    this.queryParam.limit = size;
    this.getList()
  }

  /**
   * 通过priceAttr id获取对应的属性对象
   */
  getProductPriceAttrItemById (id: string, list: any) {
    if (!id || !list || !list.length) {
      return {}
    }
    let item = list.find((item: any) => item.productPriceAttrId === id)
    if (!item) {
      return {}
    }
    return item
  }

  getList () {
    this.tableLoading = true
    const query: IScaleParam = deepCopy(this.queryParam)
    if (query.productPriceAttr && query.productPriceAttr.length) {
      query.productPriceAttr = this.queryParam.productPriceAttr.filter((item: any) => (item.status !== '' && item.status !== null && item.status !== undefined))
    }
    cashierScaleQueryApi(query).then(e => {
      if (e) {
        this.tableTotal = e.total;
        this.tableData = e.list
        this.tableLoading = false
      }
    })
  }

  statusChange (id: string, key: string, val: any) {
    let obj: any = {
      id
    }
    obj[key] = val
    cashierScaleModifyApi(obj).then(e => {
      this.$message.success("操作成功!")
      this.getList()
    })
  }

  /**
   * 打开新增弹窗
   */
  openRowAdd () {
    this.dialogVisible = true
  }


  beforeClose (done: Function) {
    if (this.formLoading) {
      return
    }
    done()
  }

  dialogClose () {
    if (this.$refs.FormRef) {
      // 清空表单校验规则
      // @ts-ignore
      this.$refs.FormRef.clearValidate()
      // 清空表单数据
      // @ts-ignore
      this.$refs.FormRef.resetFields()
      this.modelForm = {}
    } else {
      this.modelForm = {}
    }
  }

  rowSave () {
    // @ts-ignore
    this.$refs.FormRef.validate(valid => {
      if (valid) {
        this.formLoading = true
        cashierScaleCreateApi(this.modelForm as IScale).then(e => {
          if (e) {
            this.$message.success("操作成功!")
            this.getList()
            this.dialogVisible = false
          }
        }).finally(() => {
          this.formLoading = false
        })
      }
    })
  }

  rowEdit () {
    // @ts-ignore
    this.$refs.FormRef.validate(valid => {
      if (valid) {
        this.formLoading = true
        cashierScaleModifyApi(this.modelForm as IScale).then(e => {
          if (e) {
            this.$message.success("操作成功!")
            this.getList()
            this.dialogVisible = false
          }
        }).finally(() => {
          this.formLoading = false
        })
      }
    })
  }

  openDetail (id: string) {
    cashierScaleDetailApi(id).then(e => {
      if (e) {
        this.modelForm = e;
        this.dialogVisible = true
      }
    })
  }

  disableScale (id: string) {
    this.$confirm('确认废弃？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      cashierScaleModifyApi({id, status: 2} as IScale).then(e => {
        if (e) {
          this.$message.success("操作成功!")
          this.getList()
        }
      })
    }).catch(() => {

    })
  }

  attrStatusChange (row: IScale, productPriceAttrId: string, status: number) {
    let item: any = row.cashierScaleAttrVos.find(item => item.productPriceAttrId === productPriceAttrId)
    cashierScaleUpdateScaleAttrApi({cashierScaleId: item.cashierScaleId, productPriceAttrId, status}).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
      }
    })
  }

  async created () {
    await this.getAttrList()
    this.getGateList()
    this.getList()
  }
}
