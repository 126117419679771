/**
 * @name: 商品管理-商品价格属性管理接口文件
 * @author:
 * @date: 2023-08-04 17:46
 * @description： 商品管理-商品价格属性管理接口文件
 * @update: 2023-08-04 17:46
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import { IProductPriceAttr } from "./types";

/**
 * 商品价格属性列表查询
 * @returns
 */
export const productPriceAttrQueryApi = () => get<IProductPriceAttr[]>("/admin/productPriceAttr/query")

/**
 * 删除
 */
export const productPriceAttrRemoveApi = (id:string) => get("admin/productPriceAttr/remove/"+id)

/**
 *创建
 */
export const productPriceAttrCreateApi = (data:any) => postJ("/admin/productPriceAttr/create", data)
